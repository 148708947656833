<template>
    <v-row dense>
        <v-col>
            <div class="mb-1">
                <span
                    v-if="isLoggedIn"
                    @click="logout()"
                    role="button"
                    class="float-end font-weight-regular text-decoration-underline text-underline"
                >
                    {{ t("buttons.logout") }}
                </span>

                <label for="email">
                    {{ t("payment.form.email_address") }}
                </label>
            </div>

            <v-text-field
                v-model="model"
                v-bind="attrs"
                id="email"
                name="email"
                :placeholder="t('payment.form.email_address_placeholder')"
                @blur="onBlur"
                :hide-details="isCodeFieldActive"
                :class="{
                    active: isCodeFieldActive,
                }"
                :readonly="isCodeFieldActive || isLoggedIn"
                :loading="isLoading && !showCodeField"
            >
                <template #append-inner>
                    <v-icon
                        v-if="isLoggedIn"
                        icon="custom:check"
                        color="success"
                    ></v-icon>

                    <v-icon
                        v-else-if="hasSkipped"
                        icon="custom:phone"
                        @click="showLogin"
                    />
                </template>
            </v-text-field>

            <div
                v-if="isCodeFieldActive && !isLoggedIn"
                class="code__container"
            >
                <div class="code__close" role="button">
                    <v-icon icon="mdi-close" @click="skip"></v-icon>
                </div>

                <div
                    v-if="!pendingDeleteDetails"
                    class="code__body code__body--login"
                >
                    <div class="code__desc">
                        <p class="font-weight-bold mb-1">
                            {{ t("verify.saved_email.checkout_faster") }}
                        </p>
                        <p>
                            {{
                                t("verify.saved_email.enter_code", {
                                    phone: customerStore.phoneNumber.slice(-3),
                                })
                            }}
                        </p>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <v-icon
                            icon="custom:phone"
                            size="36px"
                            class="pa-2"
                        ></v-icon>
                        <v-otp-input
                            v-model="code"
                            placeholder="-"
                            max-height="36px"
                            max-width="265px"
                            rounded="2px"
                            ref="otpInput"
                            :error="errorMessage !== ''"
                            @finish="verifyLoginCode"
                        ></v-otp-input>
                        <Loader
                            v-if="isLoading"
                            class="ma-2"
                            size="20px"
                            border-size="3px"
                        ></Loader>
                    </div>
                    <div class="text-error mt-n4" style="height: 24px">
                        {{ errorMessage }}
                    </div>
                </div>

                <div
                    v-if="pendingDeleteDetails"
                    class="code__body code__body--remove-details"
                >
                    <div class="code__desc">
                        <p class="font-weight-bold mb-1">
                            {{
                                t("payment.saved_payments.remove_details.title")
                            }}
                        </p>

                        <p>
                            {{
                                t(
                                    "payment.saved_payments.remove_details.description",
                                )
                            }}
                        </p>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <v-icon
                            icon="custom:email"
                            size="36px"
                            class="pa-2"
                        ></v-icon>
                        <v-otp-input
                            v-model="code"
                            placeholder="-"
                            max-height="36px"
                            max-width="265px"
                            rounded="2px"
                            ref="otpInput"
                            :error="errorMessage !== ''"
                            @finish="verifyRemoveDetailsCode"
                        ></v-otp-input>
                        <Loader
                            v-if="isLoading"
                            class="ma-2"
                            size="20px"
                            border-size="3px"
                        ></Loader>
                    </div>
                    <div class="text-error mt-n4" style="height: 24px">
                        {{ errorMessage }}
                    </div>
                </div>

                <div class="code__actions">
                    <span
                        v-if="!pendingDeleteDetails"
                        role="button"
                        @click="removeMyDetails"
                        :class="{
                            'text-gray-800': theme.current.value.dark,
                            'text-gray-500': !theme.current.value.dark,
                            'link-disabled': isLoading,
                        }"
                    >
                        {{ t("buttons.remove_my_details") }}
                    </span>
                    <div class="flex-grow-1"></div>
                    <span
                        v-if="pendingDeleteDetails"
                        role="button"
                        @click="removeMyDetails"
                        class="text-primary"
                        :class="{
                            'link-disabled': isLoading,
                        }"
                    >
                        {{ t("buttons.resend_code") }}
                    </span>
                    <span
                        v-else
                        role="button"
                        @click="submitEmailDebounced"
                        class="text-primary"
                        :class="{
                            'link-disabled': isLoading,
                        }"
                    >
                        {{ t("buttons.resend_code") }}
                    </span>
                    <span
                        role="button"
                        @click="skip"
                        :class="{
                            'text-secondary': theme.current.value.dark,
                            'text-gray-500': !theme.current.value.dark,
                            'link-disabled': isLoading,
                        }"
                    >
                        {{ t("buttons.skip") }}
                    </span>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import {
    ref,
    useAttrs,
    nextTick,
    onMounted,
    watch,
    inject,
    computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useTheme } from "vuetify/lib/framework.mjs";
import { VOtpInput, VTextField } from "vuetify/lib/components/index.mjs";
import Loader from "../app/Loader.vue";
import { useDebounceFn, watchTriggerable } from "@vueuse/core";
import { useField } from "vee-validate";
import { useCustomerStore } from "@/store/customer";
import { storeToRefs } from "pinia";
import { usePaymentMethodStore } from "@/store/payment-method";

const props = defineProps<{
    savedPaymentsActive: boolean;
}>();

const attrs = useAttrs();
const model = defineModel<string>();

const emit = defineEmits(["blur", "open", "close"]);
const setFieldsDisabled = inject("setFieldsDisabled", (v: boolean) => v);

const { t } = useI18n();
const route = useRoute();
const theme = useTheme();
const customerStore = useCustomerStore();
const paymentMethodStore = usePaymentMethodStore();
// Used to validate the field
const { errors, validate: validateEmail } = useField("email");

const basketId = route.params.basketId as string;

const { isLoggedIn, hasSkipped, pendingDeleteDetails, savedPaymentMethods } =
    storeToRefs(customerStore);
const showCodeField = ref(false);
const code = ref("");
const isLoading = ref(false);
const errorMessage = ref("");
const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null);

const isCodeFieldActive = computed(() => showCodeField.value);

// When the verify code field is shown/hidden, disable/enable the other form fields
watch(showCodeField, () => {
    if (props.savedPaymentsActive && showCodeField.value)
        setFieldsDisabled(true);
    else {
        customerStore.cancelRemoveDetails();
        setFieldsDisabled(false);
    }
});

// Watch the email value for changes
const { trigger: customerLookup } = watchTriggerable(model, async () => {
    // Validate the email address
    const { valid } = await validateEmail();
    // When the email becomes valid send a request to the server to get the customer UUID
    if (valid && !hasSkipped.value) {
        await submitEmailDebounced();
    }
});

// When the customer UUID changes, allow the user to enter their code
watch(
    () => customerStore.uuid,
    () => {
        if (customerStore.uuid) {
            showOtpInput();
        }
    },
);

// When the email is cleared reset the login state
watch(
    () => model.value,
    () => {
        if (!model.value) {
            hasSkipped.value = false;
        }
    },
);

// TODO: (saved payment methods) Should this be moved into its own service/store function?
const submitEmail = async () => {
    errorMessage.value = "";
    isLoading.value = true;
    try {
        await customerStore.lookup(basketId, model.value!);
    } catch (error: any) {
        // TODO: (saved payment methods) localize message somehow?
        const message =
            error?.response?.data?.message ?? "Unable to verify email";
        console.error(message);
        errorMessage.value = message;
    } finally {
        isLoading.value = false;
    }
};

const submitEmailDebounced = useDebounceFn(submitEmail, 200);

const verifyLoginCode = async () => {
    isLoading.value = true;

    try {
        await customerStore.login(basketId, code.value);

        if (savedPaymentMethods.value?.length) {
            // Select the first payment method
            paymentMethodStore.setSelectedPaymentMethodIdent(
                savedPaymentMethods.value[0].payment_method,
            );
        }

        code.value = "";
        errorMessage.value = "";
        showCodeField.value = false;
    } catch (error: any) {
        // TODO: (saved payment methods) localize message somehow?
        const message =
            error?.response?.data?.message ?? "Unable to verify code";
        console.error(message);
        errorMessage.value = message;
    } finally {
        isLoading.value = false;
    }
};

const verifyRemoveDetailsCode = async () => {
    isLoading.value = true;

    try {
        await customerStore.confirmRemoveDetails(code.value);
        // TODO: (saved payment methods) I think it would be good to show a message somewhere to show the user that their details were successfully removed
        code.value = "";
        errorMessage.value = "";
        showCodeField.value = false;
    } catch (error: any) {
        // TODO: (saved payment methods) localize message somehow?
        const message =
            error?.response?.data?.message ?? "Unable to verify code";
        console.error(message);
        errorMessage.value = message;
    } finally {
        isLoading.value = false;
    }
};

const removeMyDetails = async () => {
    if (isLoading.value) {
        return;
    }

    isLoading.value = true;
    code.value = "";
    errorMessage.value = "";

    try {
        await customerStore.beginRemoveDetails();
        errorMessage.value = "";
    } catch (error: any) {
        // TODO: (saved payment methods) localize message somehow?
        const message =
            error?.response?.data?.message ??
            "An error occurred while removing details";
        console.error(message);
        errorMessage.value = message;
    } finally {
        isLoading.value = false;
    }
};

const skip = () => {
    if (isLoading.value) {
        return;
    }

    showCodeField.value = false;
    code.value = "";
    errorMessage.value = "";
    hasSkipped.value = true;
};

const showOtpInput = () => {
    // Ensure there are no email validation errors
    if (errors.value.length > 0) {
        return;
    }

    // Also, ensure the customer hasn't already logged in
    if (customerStore.isLoggedIn) {
        return;
    }

    // Also, ensure the code field is not already displayed
    if (showCodeField.value) {
        return;
    }

    // Also, ensure the user has not skipped the code field
    if (hasSkipped.value) {
        return;
    }

    showCodeField.value = true;
    errorMessage.value = "";

    // Focus on the otp input
    nextTick(() => {
        otpInput.value?.focus();
    });
};

const onBlur = () => {
    emit("blur");
};

/**
 * Override hasSkipped and begin the login flow again
 */
const showLogin = () => {
    hasSkipped.value = false;
    // If the user has already entered their email, perform the customer lookup
    customerLookup();
};

const logout = () => {
    model.value = "";
    customerStore.logout();
};

onMounted(() => {
    // If the user has already entered their email, perform the customer lookup
    // Note: remount seems to happen when changing payment method?
    // This would knock customerStore.isLoggedIn back to false, which allows the OTP input to show up again
    // So now we only do this if the user hasn't logged in yet
    if (!customerStore.isLoggedIn) customerLookup();
});
</script>

<style scoped lang="scss">
:deep(.v-field__outline__start),
:deep(.v-field__outline__end) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.active {
    :deep(input.v-field__input) {
        opacity: 0.5;
    }
}

:deep(input[readonly]) {
    opacity: 0.5;
}

.code__body {
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    align-self: stretch;
    .v-otp-input {
        padding: 0;

        &__content {
            padding: 0;
        }
    }
}

.code__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
}

:global(.code__body .v-otp-input__content) {
    padding: 0;
}

.code__actions {
    display: flex;
    height: 32px;
    padding: 6px 12px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    font-size: 12px;
    background: rgb(var(--v-theme-background));
    border-top: 1px solid rgb(var(--v-theme-field-border));

    span {
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: 0px;
        text-underline-offset: 3px;
        text-underline-position: from-font;
    }
}

.code__desc {
    p {
        font-size: 12px;
        text-align: left;
    }
}

.code__container {
    position: relative;
    background-color: rgb(var(--v-theme-fields));
    border: 1px solid rgb(var(--v-theme-field-border));
    border-top-width: 0px;
    margin-bottom: 10px;
    border-radius: 0px 0px 2px 2px;
}

.link-disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
