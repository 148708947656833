import { getUpsells, validateOptions } from "@/services/upsell";
import UpsellItem, { UpsellItemOption } from "@/types/UpsellItem";
import { useAsyncState } from "@vueuse/core";
import { defineStore } from "pinia";
import { type ComputedRef, type Ref, ref, computed, reactive } from "vue";
import { useBasketStore } from "./basket";
import { useAppStore } from "./app";
import { useRoute, useRouter } from "vue-router";

interface State {
    items: ComputedRef<UpsellItem[]>;
    error: Ref<unknown>;
    shouldShowLoader: ComputedRef<boolean>;
    selectedUpsellIds: UpsellItem["uuid"][];
    selectedUpsells: ComputedRef<UpsellItem[]>;
    loadingUpsells: Ref<Set<UpsellItem["uuid"]>>;
    viewedUpsellItem: ComputedRef<UpsellItem | null>;
    isInitialLoad: Ref<boolean>;
    isReady: Ref<boolean>;
    fetchUpsells: (basketId: string) => Promise<{
        items: UpsellItem[];
    } | null>;
    selectUpsell: (uuid: UpsellItem["uuid"]) => void;
    deselectUpsell: (uuid: UpsellItem["uuid"]) => void;
    validateUpsellOptions: (
        packageId: number,
        options: Record<string, string>,
    ) => Promise<void> | void;
    viewUpsell: (uuid: UpsellItem["uuid"]) => void;
    parseUpsellOptions: (upsellItem: UpsellItem) => Record<string, string>;
    addUpsellToBasket: (
        upsellItem: UpsellItem,
        options?: Record<string, string> | undefined,
    ) => Promise<void>;
    removeUpsellFromBasket: (upsellItem: UpsellItem) => Promise<void>;
}

export const useUpsellStore = defineStore("upsell", function (): State {
    const isInitialLoad = ref<boolean>(true);
    const basketStore = useBasketStore();
    const appStore = useAppStore();
    const upsellItem = ref<UpsellItem | null>(null);
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref<boolean>(true);

    const { state, isReady, execute, error } = useAsyncState(
        async (args) => {
            const basketId = args?.basketId || null;
            const items = await getUpsells(basketId);

            isInitialLoad.value = false;

            return {
                items,
            };
        },
        null,
        { immediate: false, resetOnExecute: false },
    );

    const items = computed(() => state.value?.items ?? []);
    const shouldShowLoader = computed(() => {
        return appStore.isConfigLoading || isLoading.value;
    });

    const selectedUpsellIds = reactive<UpsellItem["uuid"][]>([]);

    const selectedUpsells = computed<UpsellItem[]>(() => {
        return selectedUpsellIds.map<UpsellItem>((uuid) => {
            return items.value.find((item) => item.uuid === uuid) as UpsellItem;
        });
    });

    const loadingUpsells = ref(new Set<UpsellItem["uuid"]>());

    const viewedUpsellItem = computed(() => upsellItem.value);

    async function fetchUpsells(basketId: string) {
        isLoading.value = true;

        const data = await execute(0, { basketId });

        if (
            data?.items.length === 0 &&
            !basketStore.isBasketCompleted &&
            !(window as any).TBX_PAYMENT_DATA &&
            route.name !== "payment-completed" &&
            route.name !== "payment-error" &&
            route.name !== "3ds" &&
            route.name !== "enter-phone" &&
            route.name !== "verify-email" &&
            route.name !== "verify-phone"
        ) {
            await router.push({ name: "payment" });
        }

        isLoading.value = false;

        return data;
    }

    function selectUpsell(uuid: UpsellItem["uuid"]) {
        selectedUpsellIds.push(uuid);
    }

    function deselectUpsell(uuid: UpsellItem["uuid"]) {
        const index = selectedUpsellIds.findIndex((id) => id === uuid);

        if (index > -1) {
            selectedUpsellIds.splice(index, 1);
        }
    }

    function viewUpsell(uuid: UpsellItem["uuid"]) {
        upsellItem.value =
            items.value.find((item) => item.uuid === uuid) ?? null;
    }

    async function validateUpsellOptions(
        packageId: number,
        options: Record<string, string>,
    ) {
        if (!basketStore.basketId) return;

        await validateOptions(basketStore.basketId, packageId, options);
    }

    function parseUpsellOptions(upsellItem: UpsellItem) {
        return upsellItem.options.reduce(
            (acc, option: UpsellItemOption) => {
                console.log({ option });
                const selectedValue = option.value;

                if (option.type === "dropdown") {
                    const id = option?.options?.find(
                        (o) => o.value === selectedValue,
                    )?.id;

                    acc[option.name] = id ? String(id) : "";
                } else if (option.name === "discord_id") {
                    acc["discord_signature"] = option.discordSignature ?? "";
                } else {
                    acc[option.name] = String(selectedValue) ?? "";
                }
                return acc;
            },
            {} as Record<string, string>,
        );
    }

    async function addUpsellToBasket(
        upsellItem: UpsellItem,
        options?: Record<string, string> | undefined,
    ) {
        // Mark as loading
        loadingUpsells.value.add(upsellItem.uuid);

        try {
            // Add upsell to basket
            await basketStore.addUpsell(upsellItem.uuid, options);

            // Select upsell
            selectUpsell(upsellItem.uuid);
        } catch (error) {
            console.error(error);
        }

        loadingUpsells.value.delete(upsellItem.uuid);
    }

    async function removeUpsellFromBasket(upsellItem: UpsellItem) {
        // Mark as loading
        loadingUpsells.value.add(upsellItem.uuid);

        // Find item in basket
        const item = basketStore.getItemByPackageId(upsellItem.packageId);

        if (item?.id) {
            // Remove from basket
            await basketStore.removePackage(String(item.id));
            // De-select upsell
            deselectUpsell(upsellItem.uuid);
        }

        loadingUpsells.value.delete(upsellItem.uuid);
    }

    return {
        items,
        error,
        shouldShowLoader,
        selectedUpsellIds,
        selectedUpsells,
        loadingUpsells,
        viewedUpsellItem,
        isInitialLoad,
        isReady,
        fetchUpsells,
        selectUpsell,
        deselectUpsell,
        validateUpsellOptions,
        viewUpsell,
        parseUpsellOptions,
        addUpsellToBasket,
        removeUpsellFromBasket,
    };
});
