class PopupManager {
    private popup: Window | null = null;

    public createBlankPopup() {
        // Redirect to the payment page
        const url = new URL(window.location.href);
        url.pathname = "/redirect";

        this.popup = window.open(url.href, "_blank");

        if (!this.popup) {
            throw Error("Failed to create popup: window.open returned null.");
        }

        return this.popup;
    }

    public closePopup() {
        if (this.popup) {
            this.popup.close();
            this.popup = null;
        }
    }

    public getPopup(): Window | null {
        return this.popup;
    }

    public createOrReusePopup(): Window | null {
        if (this.popup && !this.popup.closed) {
            return this.popup;
        }

        try {
            return this.createBlankPopup();
        } catch (error) {
            return null;
        }
    }
}

export default new PopupManager();
