<template>
    <span class="loader" :style="style"></span>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";

const props = defineProps<{
    size?: string;
    borderSize?: string;
}>();

const style = computed(() => ({
    "--loader-size": props.size,
    "--loader-border-size": props.borderSize,
}));
</script>

<style lang="scss" scoped>
.loader {
    width: var(--loader-size, 35px);
    height: var(--loader-size, 35px);
    aspect-ratio: 1 / 1;
    border: var(--loader-border-size, 5px) solid rgb(var(--v-theme-gray-600));
    border-bottom-color: rgb(var(--v-theme-gray-300));
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
