import { i18n } from "@/plugins/i18n";
import { useMessageStore } from "@/store/message";
import { ErrorResponse } from "@/types/Api";
import axios, { AxiosError, AxiosResponse } from "axios";

export function isAxiosError<T>(error: unknown): error is AxiosError<T> {
    return axios.isAxiosError(error);
}

export function responseBody<T>(response: AxiosResponse<T>) {
    return response.data;
}

export function handleError(
    error: unknown,
    options: { showToast?: boolean } = { showToast: true },
) {
    let message;

    if (isAxiosError<ErrorResponse>(error)) {
        message = error.response?.data?.message;
    }

    if (!message) {
        message = i18n.t("error.unexpected");
    }

    if (options.showToast) {
        const messageStore = useMessageStore();
        messageStore.addMessage(message, {
            timeout: 10 * 1000,
            type: "error",
        });
    }

    throwErrorIfNecessary(error, message);
}

export function throwErrorIfNecessary(error: unknown, message?: string) {
    // Server-side errors should already be caught on the server in production,
    // so to avoid using up Sentry limits, avoid throwing them on the client too
    if (import.meta.env.MODE === "production" && isAxiosError<ErrorResponse>(error)) {
        const status = error.response?.status;
        if (!!status && status >= 400) {
            return;
        }
    }

    throw new Error(message);
}

const instance = axios.create({
    baseURL: import.meta.env.VITE_BASKET_API,
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;
