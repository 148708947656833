<template>
    <div class="container">
        <SkeletonVerifyPage
            :loading="!appStore.isReady || basketStore.isLoading"
        >
            <VerifyLayout
                :title="t('verify.enter_phone.title')"
                :subtitle="t('verify.enter_phone.subtitle')"
            >
                <PhoneInput
                    v-model="phoneNumber"
                    :default-region-code="basketStore.address?.country"
                    id="phone-input"
                    class="phone"
                    name="phone"
                />
                <div class="text-error mt-n2" style="height: 24px;">
                    {{ errorMessage }}
                </div>

                <template #actions>
                    <v-btn
                        color="primary"
                        width="140px"
                        :disabled="phoneNumber.length < 6"
                        @click="submitPhoneNumber"
                    >
                        {{ t("buttons.continue") }}
                    </v-btn>
                    <span 
                        role="button"
                        @click="skip"
                        class="link-secondary mt-2 border-b border-opacity-100"
                    >
                        {{ t("buttons.proceed_without_saving") }}
                    </span>
                </template>

                <template #footer>
                    <v-alert
                        variant="tonal"
                        type="success"
                        class="border border-success justify-center text-uppercase"
                        :text="t('payment.order_complete')"
                        density="compact"
                        height="38"
                        width="100%"
                    ></v-alert>
                </template>
            </VerifyLayout>
        </SkeletonVerifyPage>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import SkeletonVerifyPage from "@/components/skeleton/SkeletonVerifyPage.vue";
import { useAppStore } from "@/store/app";
import VerifyLayout from "@/components/verify/VerifyLayout.vue";
import PhoneInput from "@/components/inputs/PhoneInput.vue";
import api from "@/services/api";
import { useBasketStore } from "@/store/basket";
import { useCustomerStore } from "@/store/customer";

const appStore = useAppStore();
const basketStore = useBasketStore();
const customerStore = useCustomerStore();
const { t } = useI18n();
const router = useRouter();

const route = useRoute();
const basketId = route.params.basketId as string;

const { phoneNumber } = storeToRefs(customerStore);
const isSubmitting = ref(false);
const errorMessage = ref("");

const submitPhoneNumber = async () => {
    isSubmitting.value = true;
    errorMessage.value = "";

    try {
        await api.post(`/baskets/${ basketId }/verify/phone/send-code`, {
            phone: phoneNumber.value,
        });
        router.push({ name: "verify-phone" });
    }
    catch (error) {
        console.error(error);
        // TODO: will the backend give us an error message here? Or should we just display a generic one?
        errorMessage.value = "Could not update phone number";
    }
    finally {
        isSubmitting.value = false;
    }
};

const skip = () => {
    router.push({ name: "payment-completed" });
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.v-alert {
    margin-top: 120px;
}

:deep(.v-alert__prepend) {
    margin-inline-end: 6px;
}

.v-skeleton-loader {
    width: 100%;
    max-width: 478px;
    align-self: flex-start;
    margin-top: 95px;

    @media (min-width: 1024px) {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.phone {
    width: 330px;
}

.link-secondary {
    color: rgb(var(--v-theme-gray-600));
    border-color: currentColor !important;
}
</style>
