<template>
    <component
        v-if="paymentStore.setupResponse"
        :is="component"
        :data="paymentStore.setupResponse.data"
    />
</template>

<script lang="ts" setup>
import type { Component } from "vue";
import { usePaymentStore } from "@/store/payment";
import { onBeforeMount } from "vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

import SetupResponseEbanxVoucher from "@/components/payment/setup-response/SetupResponseEbanxVoucher.vue";
import SetupResponsePost from "@/components/payment/setup-response/SetupResponsePost.vue";
import SetupResponseRedirect from "@/components/payment/setup-response/SetupResponseRedirect.vue";
import SetupResponseSepa from "@/components/payment/setup-response/SetupResponseSepa.vue";
import SetupResponseAdyenVoucher from "@/components/payment/setup-response/SetupResponseAdyenVoucher.vue";
import SetupResponseEverVault3DS from "@/components/payment/setup-response/SetupResponseEverVault3DS.vue";
import SetupResponseCaptcha from "@/components/payment/setup-response/SetupResponseCaptcha.vue";

const SetupResponseComponents: {
    [key: string]: Component;
} = {
    SetupResponseEbanxVoucher,
    SetupResponsePost,
    SetupResponseRedirect,
    SetupResponseSepa,
    SetupResponseAdyenVoucher,
    SetupResponseEverVault3DS,
    SetupResponseCaptcha,
};

const router = useRouter();
const paymentStore = usePaymentStore();

onBeforeMount(() => {
    if (!paymentStore.setupResponse) {
        router.push({ name: "basket" });
    }
});

const component = computed(() => {
    let component = paymentStore.setupResponse?.component;

    if (!component) {
        throw new Error("No component found");
    }

    return SetupResponseComponents[component];
});
</script>
