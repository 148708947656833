import { ThemeDefinition } from "vuetify";

export const dark: ThemeDefinition = {
    dark: true,
    colors: {
        background: "#1A1A1A",
        surface: "#000000",
        primary: "#41C4C3",
        secondary: "#E5E5E5",
        "surface-variant": "#1A1A1A",
        success: "#00cc45",
        warning: "#e07000",
        error: "#c12e2e",
        green: "#00CC45",
        red: "#C12E2E",
        // Custom colours
        "gray-100": "#FFFFFF",
        "gray-200": "#F5F5F5",
        "gray-300": "#E5E5E5",
        "gray-400": "#DDDDDD",
        "gray-500": "#868996",
        "gray-600": "#999999",
        "gray-700": "#B2B2B2",
        "gray-800": "#808080",
        "gray-900": "#4D4D4D",
        "gray-1000": "#333333",
        // Need to align these with the light theme
        fields: "#262626",
        "field-border": "#333333",
    },
    variables: {
        "activated-opacity": 0.25,
        "medium-emphasis-opacity": 204 / 255
    },
};
