<template>
    <div
        :class="classes"
        :style="`--grid-cols: ${cols}; --item-height: ${itemHeight}`"
    >
        <v-skeleton-loader
            v-if="loading"
            class="payment-method-selector__skeleton"
            :type="`image@${skeletonNumber + 1}`"
        >
        </v-skeleton-loader>
        <v-item-group
            v-else
            v-model="selectedIdent"
            selected-class="bg-primary-opacify-1"
            mandatory
            class="w-100"
            :disabled="disabled"
        >
            <div class="grid" role="grid" aria-label="Payment Method Selector">
                <v-item
                    v-for="method in items"
                    :key="method.name"
                    v-slot="{ selectedClass, toggle, isSelected }"
                    :value="method.ident"
                >
                    <v-card
                        :data-testid="method.ident"
                        role="gridcell"
                        :aria-selected="isSelected"
                        :class="[
                            'grid-item d-flex align-center gray-500',
                            selectedClass,
                        ]"
                        :style="colStyles"
                        :height="itemHeight"
                        elevation="0"
                        border
                        @click="toggle"
                    >
                        <div class="text-h3 flex-grow-1 text-center">
                            <v-img
                                :src="
                                    theme.current.value.dark
                                        ? method.transparentImage
                                        : method.image
                                "
                                height="50px"
                            />
                        </div>
                    </v-card>
                </v-item>

                <v-skeleton-loader v-if="limitItemsShown" type="text" class="grid-span-all" :loading="loading">
                    <div class="mb-4 w-100">
                        <!-- Route depends on if we are in an iframe or not (TODO: is this still relevant?) -->
                        <v-btn
                            v-if="numTotalItems > 4"
                            to="select-payment-method"
                            class="w-100 d-flex align-center justify-center gray-500 btn-show-more"
                            border
                            height="30px"
                        >
                            {{ t("payment_method.show_more") }}
                        </v-btn>
                        <div v-else class="mb-4">
                            <!-- Spacer to boost margin when the "show more" text isn't shown -->
                        </div>
                    </div>
                </v-skeleton-loader>
            </div>
        </v-item-group>
    </div>
</template>

<script setup lang="ts">
import { PaymentMethod } from "@/types/PaymentMethod";
import { useVModel } from "@vueuse/core";
import { watch, ref, computed, ComponentPublicInstance, Ref } from "vue";
import { useTheme } from "vuetify";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

interface Props {
    items: PaymentMethod[];
    modelValue: PaymentMethod | null;
    limitItemsShown?: boolean;
    numTotalItems?: number;
    itemHeight?: string;
    cols?: number | string;
    loading: boolean;
    skeletonNumber?: number;
    disabled: boolean;
    centered?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    itemHeight: "65px",
    cols: 4,
    loading: true,
    skeletonNumber: 4,
    limitItemsShown: false,
    numTotalItems: Infinity
});

const emit = defineEmits(["update:modelValue", "change"]);

const selected = useVModel(props, "modelValue", emit);

const classes = computed(() => ({
    "payment-method-selector": true,
    "payment-method-selector--centered": props.centered && !props.loading,
    "payment-method-selector--limit-shown": props.limitItemsShown
}));

const stop = watch(
    () => props.items,
    () => {
        if (!selected.value && props.items[0]) {
            selected.value = props.items[0];
            stop();
        }
    },
);

const selectedIdent = computed<string>({
    get() {
        const value = props.items.find(
            (item) => item.ident === selected.value?.ident,
        );

        return value?.ident ?? "";
    },
    set(ident: string) {
        const value = props.items.find((item) => item.ident === ident) ?? null;

        selected.value = value;
        emit("change", value);
    },
});

const row: Ref<ComponentPublicInstance | null> = ref(null);
const colStyles = computed(() => {
    return {
        width: `calc(${row.value?.$el.offsetWidth}px / ${props.cols})`,
    };
});

const theme = useTheme();
</script>

<style lang="scss" scoped>
@use "@/styles/settings";

.grid,
.payment-method-selector__skeleton {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(var(--grid-cols), minmax(65px, 1fr));
}

.payment-method-selector--centered {
    .grid,
    .payment-method-selector__skeleton {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & > * {
            width: calc(25% - 1em);
            min-width: 100px;
        }
    }
}

.grid-item {
    background-color: rgba(var(--v-theme-gray-500), 0.05);
}

.grid-span-all {
    grid-column: 1 / -1;
}

.btn-show-more {
    font-size: 12px;
}

:deep(.v-skeleton-loader__image) {
    height: var(--item-height, 60px);
    border-radius: settings.$card-border-radius;
}

.payment-method-selector--limit-shown :deep(.v-skeleton-loader__image):nth-of-type(5) {
    max-width: 100%;
    grid-column: 1 / -1;
    height: 30px;
    margin-bottom: 24px;
}
</style>
