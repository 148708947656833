import { PaymentMethodDetails } from "@/types/PaymentMethod";
import { Field } from "@/types/PaymentMethodFields";
import { createSharedComposable } from "@vueuse/core";
import { FormContext, GenericObject, useForm } from "vee-validate";
import { Ref, reactive, unref } from "vue";

type VeeValidateField = {
    model: any;
    props: ReturnType<FormContext["defineField"]>[1];
};

export type PaymentFormContext = FormContext<GenericObject> & {
    fields: Record<string, VeeValidateField>;
    registerField: (name: string, validation: string | Function) => void;
    unregisterField: (name: string) => void;
};

const vuetifyConfig = (state: any) => ({
    props: {
        "error-messages": state.errors,
    },
});

const ValidationRules = new Map([
    ["phone_number", "min:10|max:18"],
    ["address", "min:1|max:100"],
    ["street_number", "numeric"],
    ["city", "min:1|max:80"],
    ["eft_code", "max:32"],
]);

/**
 * Composable function to create a payment form
 */
export const usePaymentForm = createSharedComposable(
    (
        paymentMethodDetails: Ref<PaymentMethodDetails>,
        formSchema: Record<string, any>,
    ) => {
        const methodDetails = unref(paymentMethodDetails);
        const validationSchema =
            buildValidationSchema(methodDetails.fields.fields as Field[]) ?? [];
        const context: FormContext = useForm({
            validationSchema,
        });

        const form: PaymentFormContext = buildForm(validationSchema);


        // Convert the fields array to a vee-validate schema object
        function fieldsToSchema(
            fields: Field[],
        ): Record<string, string | Function> {
            return Array.isArray(fields)
                ? fields.reduce(
                    (acc: Record<Field["name"], string>, field: Field) => {
                        const rules = ValidationRules.get(field.name);
                        acc[field.name] = ["required", rules].join("|");
                        return acc;
                    },
                    {},
                )
                : {};
        }

        /**
         * Create a validation schema from a Field type
         * @param fields a list of fields to create the schema from
         * @returns A validation schema object e.g. `{ phone_number: 'required|min:10|max:18' }`
         */
        function buildValidationSchema(
            fields: Field[],
        ): Record<string, string | Function> {
            // Combine with the standard validation schema
            const schema = {
                // Required for all payment methods
                ...formSchema,
                // Add each field to the schema (if any)
                ...fieldsToSchema(fields),
            };

            return schema;
        }

        /** 
         * Build a form object from a schema 
         * @param schema the schema to build the form from e.g. { phone_number: 'required|min:10|max:18' }
         * @returns a vee-validate form context with additional methods to dynamically register and unregister fields
         */
        function buildForm(
            schema: Record<string, string | Function>,
        ): PaymentFormContext {
            const formFields = Object.keys(schema).reduce(
                (acc, fieldName) => {
                    const [model, props] = context.defineField(
                        fieldName,
                        vuetifyConfig,
                    );

                    acc[fieldName] = { model, props };
                    return acc;
                },
                {} as Record<string, VeeValidateField>,
            );
            return {
                ...context,
                fields: reactive(formFields),

                /**
                 * Register a field with the form, you must unregister the field when it is no longer needed otherwise it will remain in the form and cause validation errors
                 * @param name the name of the field
                 * @param validation a validation rule or function e.g. 'required|min:3'
                 */
                registerField: (
                    name: string,
                    validation: string | Function,
                ) => {
                    validationSchema[name] = validation;

                    const [model, props] = context.defineField(
                        name,
                        vuetifyConfig,
                    );

                    form!.fields[name] = { model, props };
                },

                /**
                 * Remove a field from the form validation registry
                 * @param name the name of the field
                 */
                unregisterField: (name: string) => {
                    form.destroyPath(name);
                    delete form.fields[name];
                    delete validationSchema[name];
                },
            };
        }

        return {
            form,
        };
    },
);
