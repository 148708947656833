<template>
    <v-list-item
        :key="item.id"
        class="px-0"
        :class="{ 'has-image': item.image }"
        role="listitem"
    >
        <template v-if="item.image" v-slot:prepend>
            <v-avatar>
                <v-img :src="item.image"></v-img>
            </v-avatar>
        </template>

        <template v-slot:title>
            <span v-if="item.quantity > 1" class="quantity-counter">
                x{{ item.quantity }}
            </span>
            <span class="title">
                {{ item.name }}
            </span>
        </template>

        <v-list-item-subtitle class="d-flex flex-wrap">
            <span>
                <template v-if="item.price !== item.initialprice">
                    <s class="text-error">
                        {{ item.initialprice.toFixed(2) }} {{ currencyCode }}
                    </s>
                    {{ item.price.toFixed(2) }} {{ currencyCode }}
                </template>

                <template v-else>
                    {{ Number(item.initialprice).toFixed(2) }}
                    {{ currencyCode }}
                </template>
            </span>

            <div v-if="item.recurring" class="w-100">
                <v-chip
                    class="text-uppercase text-caption font-weight-bold my-1 pa-1"
                    :color="theme.current.value.dark ? 'gray-1000' : 'gray-300'"
                    variant="flat"
                    label
                    style="height: 18px"
                >
                    {{ formatCurrency(item.recurringPrice * item.quantity) }}
                    {{ taxMode === "exclusive" ? `(+${taxName})` : "" }}

                    {{
                        t("basket.recurring_period", {
                            recurringPeriod: item.recurringPeriod,
                        })
                    }}
                </v-chip>
            </div>
        </v-list-item-subtitle>
    </v-list-item>
</template>

<script setup lang="ts">
import BasketItem from "@/types/BasketItem";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";

interface Props {
    item: BasketItem;
    currencyCode: string;
    taxMode?: string;
    taxName?: string;
}

const props = defineProps<Props>();

const { t, locale } = useI18n();
const theme = useTheme();

const { formatCurrency } = useCurrencyFormatter(locale, props.currencyCode);
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/theme";

.title {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    // https://css-tricks.com/line-clampin/#aa-the-standardized-way
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // in case the browser doesn't support -webkit-box - only allow the title to be at most 2 lines high:
    max-height: 2lh;
}

.has-image {
    position: relative;
}

.quantity-counter {
    font-size: .9em;
    line-height: 100%;
    padding: 6px;
    border-radius: 2em;

    @include theme.dark {
        background-color: rgb(var(--v-theme-gray-900));
    }

    @include theme.light {
        background-color: rgb(var(--v-theme-gray-800));
        color: rgb(var(--v-theme-gray-100));
    }

    .has-image & {
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
