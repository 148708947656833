<template>
    <div class="threeds-container" ref="container" v-if="mode === 'iframe'" />
</template>

<script setup lang="ts">
import { cssVar, rgb } from "@/helpers/css-var";
import { onMounted, ref } from "vue";
import * as Sentry from "@sentry/browser";

interface Props {
    mode?: "popup" | "iframe";
    sessionId: string;
}

const props = withDefaults(defineProps<Props>(), { mode: "popup" });
const emit = defineEmits(["success", "failure", "error", "ready"]);

const container = ref<HTMLElement>();

function waitForEvervaultScript(timeout = 5000): Promise<void> {
    return new Promise((resolve, reject) => {
        const start = Date.now();
        const interval = setInterval(() => {
            if (typeof window.Evervault !== "undefined") {
                clearInterval(interval);
                resolve();
            } else if (Date.now() - start > timeout) {
                clearInterval(interval);
                reject(new Error("Evervault script not loaded"));
            }
        }, 100);
    });
}

function initEvervault() {
    const evervault = new Evervault(
        import.meta.env.VITE_EVERVAULT_TEAM_ID,
        import.meta.env.VITE_EVERVAULT_APP_ID,
    );

    const threeDSecure = evervault.ui.threeDSecure(props.sessionId, {
        theme: {
            styles: {
                ":root": {
                    "color-scheme": "light", // When set to dark, the iframe background is not opaque
                },
                ".overlayWindow": {
                    borderRadius: "5px",
                },
                ".overlayClose": {
                    borderRadius: 0,
                    color: rgb(cssVar("--v-theme-gray-100")),
                    backgroundColor: rgb(cssVar("--v-theme-gray-900")),
                },
                ".overlayClose:hover": {
                    backgroundColor: rgb(cssVar("--v-theme-gray-800")),
                },
            },
        },
    });

    threeDSecure.mount(props.mode === "iframe" ? container.value : undefined, {
        height: "600px",
    });

    threeDSecure.on("success", () => {
        emit("success");
    });

    threeDSecure.on("failure", () => {
        emit("failure");
        Sentry.captureMessage("3DS: Failure");
    });

    threeDSecure.on("error", (error: { code: string; message: string }) => {
        emit("error", error);
        Sentry.captureException(error);
    });

    threeDSecure.on("ready", () => {
        emit("ready");
    });
}

onMounted(async () => {
    await waitForEvervaultScript();

    initEvervault();
});
</script>

<style lang="scss" scoped>
.threeds-container {
    width: 100%;
    height: 600px;
    max-width: 500px;
    border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
    overflow: hidden;
}

:deep(iframe) {
    width: 500px !important;
    height: 600px !important;
}
</style>
