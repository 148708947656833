<template>
    <v-input v-bind="$attrs" :errorMessages="errors" v-model="model">
        <div class="phone-input">
            <v-autocomplete
                class="phone-input__region"
                v-model="regionCode"
                :items="items"
                :custom-filter="filter"
                :hide-details="true"
                @update:modelValue="regionCode && telInput?.focus()"
            >
                <template v-slot:selection="{ item }">
                    <div v-if="item.raw.code" class="phone-input__flag">
                        {{ getFlagEmoji(item.raw.code) }}
                    </div>
                </template>

                <template v-slot:item="{ props, item }">
                    <v-list-item
                        class="phone-input__option"
                        v-bind="props"
                        :subtitle="item.raw.title"
                        :title="item.raw.country"
                        width="320px"
                    >
                        <template v-slot:prepend>
                            <div class="phone-input__flag">
                                {{ getFlagEmoji(item.raw.code) }}
                            </div>
                        </template>
                    </v-list-item>
                </template>
            </v-autocomplete>

            <v-text-field
                class="phone-input__number"
                v-model="nationalNumber"
                type="tel"
                :error="errors.length > 0"
                :hide-details="true"
                :id="id"
                :name="name"
                ref="telInput"
            >
                <template v-slot:prepend-inner>
                    <span class="text-gray-700">
                        {{ countryCode ? `+${countryCode}` : "" }}
                    </span>
                </template>
            </v-text-field>
        </div>
    </v-input>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { levenshteinDistance } from "@/helpers/levenshtein";
// TODO: this library doesn't provide the country's name in its native language, would be nice to include that...
import { getName } from "country-list";
import {
    parsePhoneNumber,
    getSupportedRegionCodes,
    getCountryCodeForRegionCode,
} from "awesome-phonenumber";

const { t } = useI18n();
const emit = defineEmits(["blur"]);
const model = defineModel<string>();
const props = withDefaults(
    defineProps<{
        id: string;
        name: string;
        defaultRegionCode?: string;
    }>(),
    {
        defaultRegionCode: "",
    },
);

const telInput = ref<HTMLInputElement>();

const regionCode = ref<string>(props.defaultRegionCode); // e.g. "GB"
const countryCode = computed(() => getCountryCodeForRegionCode(regionCode.value)); // e.g. "+44"
const nationalNumber = ref<string>("");
const errors = ref<string[]>([]);

watch([regionCode, nationalNumber], ([code, num]) => {
    const hasCode = code !== "" && code !== null;
    const countryCode = getCountryCodeForRegionCode(code);
    errors.value = [];

    // Maybe user entered their whole phone number (including region code) into text input
    let parsed = parsePhoneNumber(num);

    if (!hasCode && parsed.valid) {
        regionCode.value = parsed.regionCode;
        nationalNumber.value = parsed.number.international.replace("+" + parsed.countryCode, "");
        model.value = parsed.number?.international;
        return;
    }

    // Maybe user entered their whole phone number but forgot to prefix with "+"
    parsed = parsePhoneNumber("+" + num);
    if (!hasCode && parsed.valid) {
        regionCode.value = parsed.regionCode;
        nationalNumber.value = parsed.number.international.replace("+" + parsed.countryCode, "");
        model.value = parsed.number?.international;
        return;
    }

    // Maybe user entered just the national number, and selected the region code from the dropdown
    parsed = parsePhoneNumber("+" + countryCode + num);
    if (hasCode && parsed.valid) {
        regionCode.value = parsed.regionCode;
        nationalNumber.value = parsed.number.international.replace("+" + parsed.countryCode, "");
        model.value = parsed.number?.international;
        return;
    }

    // Number isn't valid
    errors.value = [t("validation.messages.phoneInvalid")];
    model.value = `+${ countryCode }${ num }`; // Just give whatever the user has entered so far
});

// TODO: What happens if a customer tries to use a number from a country that isn't in this list? This would be an 'invalid' number...
const items = getSupportedRegionCodes()
    .map((code) => ({
        code: code,
        title: `+${getCountryCodeForRegionCode(code)}`,
        country: getName(code),
        value: code,
    }))
    .filter((item) => !!item.country && !!item.title)
    .sort((a, b) => a.country!.localeCompare(b.country!));

const filter = (_: string, queryText: string, item: any) => {
    const q = queryText.toLocaleLowerCase();
    const countryCode = item.raw.title.toLocaleLowerCase() as string;
    const countryName = item.raw.country.toLocaleLowerCase() as string;
    const regionCode = item.raw.code.toLocaleLowerCase() as string;
    return (
        countryName.includes(q) ||
        levenshteinDistance(q, countryName) < 3 ||
        regionCode.includes(q) ||
        countryCode.includes(q)
    );
};

const getFlagEmoji = (code: string) => {
    // Paraphrasing Wikipedia - https://en.wikipedia.org/wiki/Regional_indicator_symbol
    // Flag emojis are designed so that they map to the letters of the country code (e.g. "US").
    // Each letter should be converted to a unicode "Regional Indicator Symbol", then combining those symbols together gives you the map emoji.
    // This block of symbols starts at U+1F1E6 for the letter "A".
    // So to convert a regular ASCII char to a Regional Indicator Symbol:
    // - Start with the base offset of 0x1F1E6
    // - Subtract the ASCII code for "A", which is 65
    // - Add the ASCII code of your given letter
    const chars = code.toUpperCase().split("");
    const codePoints = chars.map((c) => 0x1f1e6 - 65 + c.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
};
</script>

<style lang="scss">
.phone-input {
    width: 100%;
    display: grid;
    grid-template-columns: 75px 1fr;
    border-start-start-radius: 0;
    border-end-start-radius: 0;
}

.phone-input__flag {
    display: block;
    font-size: 20px;
    margin-right: 8px;
}

.phone-input__region {
    .v-field {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .v-field:not(.v-field--focused) .v-field__outline__end {
        border-right-width: 0 !important;
    }
}

.phone-input__number {
    .v-field {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.phone-input__option {
    .v-list-item-title {
        display: block !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.v-menu > .v-overlay__content > .v-list {
    background-color: rgb(var(--v-theme-surface-variant));
}
</style>
