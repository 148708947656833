<template>
    <v-app>
        <Head>
            <meta
                v-if="platform.ios"
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            />
        </Head>

        <router-view />

        <v-dialog
            width="500"
            v-for="message in messages"
            :key="message.id"
            :color="message.options.type"
            v-model="message.show"
        >
            <v-card>
                <v-card-text class="text-center my-3">
                    <v-icon icon="mdi-alert" size="x-large" />
                    <p class="mt-3">{{ message.message }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="mx-auto mb-3"
                        color="secondary"
                        variant="flat"
                        text="Close"
                        @click="message.show = false"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-for="toast in toasts"
            :key="toast.id"
            v-model="toast.show"
            :color="toast.options.type"
            :timeout="toast.options.timeout"
            location="bottom right"
        >
            <div class="w-100 text-center text-subtitle-1">
                {{ toast.message }}
            </div>
        </v-snackbar>
    </v-app>
</template>

<script setup lang="ts">
import { useDisplay, useTheme } from "vuetify";
import { onBeforeMount, computed, watchEffect } from "vue";
import { ThemeColor } from "@/types/ThemeColor";
import { useZoid } from "@/composables/useZoid";
import { useMessageStore } from "@/store/message";
import { Head } from "@unhead/vue/components";
import { useAppStore } from "@/store/app";
import { createRoktLauncher } from "@/composables/useRokt";
import { storeToRefs } from "pinia";
import { useToastStore } from "@/store/toast";
import { useHead } from "@unhead/vue";
import * as Sentry from "@sentry/browser";

useHead({
    script: [
        {
            src: "https://pay.tebex.io/assets/payments.js",
            onerror: (error) => {
                console.log("Failed to load Evervault script", error);
                Sentry.captureMessage("Failed to load Evervault script");
                Sentry.captureException(error);
            },
        },
    ],
});

const theme = useTheme();
const zoid = useZoid();
const { platform } = useDisplay();

const messageStore = useMessageStore();
const { messages } = storeToRefs(messageStore);

const toastStore = useToastStore();
const { toasts } = storeToRefs(toastStore);

const appStore = useAppStore();
const { config } = storeToRefs(appStore);

appStore.watchForLocaleChange();

createRoktLauncher(
    "3027691557145027972",
    import.meta.env.MODE === "development",
    computed(() => config.value?.shouldDisplayOffers ?? false),
);

type TebexJsThemeOption = "light" | "dark" | "default" | "auto";
type BasicTheme = "light" | "dark";

const getConfigTheme = (): BasicTheme => {
    const isDark = config.value?.darkMode;
    return isDark == "1" ? "dark" : "light";
};

const resolveTheme = (theme: TebexJsThemeOption): BasicTheme => {
    // Use store config theme
    if (theme === "default") return getConfigTheme();

    // Use system theme preference
    if (theme === "auto") {
        const isDark = matchMedia("(prefers-color-scheme: dark)");
        return isDark.matches ? "dark" : "light";
    }

    return theme;
};

watchEffect(() => {
    if (!zoid.isChild()) {
        theme.global.name.value = getConfigTheme();
    }
});

onBeforeMount(() => {
    if (zoid.isChild() || window.opener) {
        // Zoid provides xprops via the current window object even if opened in a new browser window
        // Attempting to access window.opener.xprops seems to result in a cross-origin error, but keeping it as a fallback since I'm assuming there was a reason for using it in the first place?
        const win = zoid.isChild() ? window : window.opener;
        const xprops = win.xprops;

        theme.global.name.value = resolveTheme(xprops?.theme ?? "default");

        if (xprops?.locale) {
            appStore.setLocale(xprops.locale);
        }

        if (xprops?.colors) {
            xprops.colors.forEach((c: ThemeColor) => {
                if (["primary", "secondary"].includes(c.name)) {
                    theme.themes.value[theme.global.name.value].colors[c.name] =
                        c.color;
                }
            });
        }

        if (xprops?.closeOnEsc) {
            document.body.addEventListener("keydown", (e: KeyboardEvent) => {
                if (e.key === "Escape") {
                    zoid.xprops.onClosePopup();
                }
            });
        }
    }
});

window.addEventListener("message", (event) => {
    if (event.data.name === "tbx") {
        if (event.data.payload.type === "error") {
            messageStore.addMessage(event.data.payload.message, {
                type: "error",
                timeout: 5000,
            });
        }
    }
});

if (import.meta.env.DEV) {
    (window as any).__setTheme = (name: BasicTheme) => {
        theme.global.name.value = name;
    };

    (window as any).__toggleTheme = () => {
        const name = theme.global.name.value;
        theme.global.name.value = name === "dark" ? "light" : "dark";
    }
}
</script>
