<template>
    <div v-if="shouldShowFields" class="form-grid">
        <div v-for="field in config.fields" :key="field.name">
            <label :for="field.name">
                {{ field.label }}
            </label>

            <!-- Text -->
            <v-text-field
                v-if="field.type === 'text'"
                v-model="form.fields[field.name].model"
                v-bind="form.fields[field.name].props"
                :id="field.name"
                :name="field.name"
            ></v-text-field>

            <!-- Select -->
            <v-select
                v-if="field.type === 'select'"
                v-model="form.fields[field.name].model"
                v-bind="form.fields[field.name].props"
                :id="field.name"
                :name="field.name"
                :items="field.items"
                item-title="name"
                item-value="id"
                variant="outlined"
            ></v-select>

            <!-- Phone number -->
            <PhoneInput
                v-if="field.type === 'InputTel'"
                v-model="form.fields[field.name].model"
                v-bind="form.fields[field.name].props"
                :id="field.name"
                :name="field.name"
            ></PhoneInput>
        </div>
    </div>
</template>

<script lang="ts" setup>
import PhoneInput from "@/components/inputs/PhoneInput.vue";
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { FieldsPayPal } from "@/types/PaymentMethodFields";
import { computed, toRef, onBeforeUnmount, onMounted, watch } from "vue";

interface Props {
    config: FieldsPayPal;
    form: PaymentFormContext;
}

const props = defineProps<Props>();
const form = toRef(props, "form");

const paymentStore = usePaymentStore();

/**
 * Determines if the additional fields should be shown
 */
const shouldShowFields = computed(() => {
    // Always show fields if the basket has recurring items
    if (props.config.fields_required) {
        return true;
    }

    // If the basket is not recurring, but the user wants to save the payment method, show the additional fields
    if (
        !props.config.fields_required &&
        form.value.values.savedPaymentsActive
    ) {
        return true;
    }

    return false;
});

/**
 * When showing/hiding the fields, register/unregister them in the form
 * This is necessary to validate the fields when submitting the form
 */
watch(
    shouldShowFields,
    () => {
        const form = paymentStore.getActiveForm();

        if (!form) {
            return;
        }

        // Register/unregister the fields in the form
        props.config.fields.forEach((field) => {
            if (shouldShowFields.value) {
                form.registerField(field.name, "required");
            } else {
                form.unregisterField(field.name);
            }
        });
    },
    { immediate: true },
);

onMounted(() => {
    paymentStore.setFieldsReady(true);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>
