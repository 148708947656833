<template>
    <div v-if="!error" ref="applePay"></div>
    <div v-else>{{ error }}</div>
</template>

<script setup lang="ts">
import { Ref, ref } from "vue";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { type Adyen, createApplePayComponent } from "@/services/adyen";
import { useAdyen } from "@/composables/useAdyen";

const appStore = useAppStore();
const basketStore = useBasketStore();

const applePay = ref(null);
const error: Ref<string | null> = ref(null);

const emit = defineEmits(["change"]);

function createApplePay(checkout: Adyen) {
    if (appStore.config === null) {
        error.value = "No config found";
        return;
    }

    if (basketStore.price === null) {
        error.value = "No balance found";
        return;
    }

    const amount = {
        currency: basketStore.currencyCode,
        value: Math.round(basketStore.price.balance * 100),
    };

    const component = createApplePayComponent(checkout, {
        amount,
        configuration: {
            merchantDisplayName: "TEBEX.ORG",
            merchantId: "000000000200147",
            merchantIdentifier: "000000000200147",
            merchantName: "TEBEX.ORG",
            displayName: "TEBEX.ORG",
        } as any,
        totalPriceLabel: "Tebex Purchase",
        countryCode: basketStore.address?.country == "US" ? "US" : "GB",
        buttonType: "buy",
        showPayButton: true,
        buttonColor: "white",
        async onChange(state: any) {
            if (state.isValid && basketStore.basketId) {
                component.setStatus("loading");
                emit("change", state.data);
            }
        },
    });

    component.isAvailable().then((available) => {
        if (available && applePay.value) {
            component.mount(applePay.value);
        }
    });
}

useAdyen({ onCheckoutCreate: createApplePay });
</script>

<style lang="scss">
@use "@/styles/mixins/theme";

.adyen-checkout__applepay__button {
    -webkit-appearance: -apple-pay-button !important;
    width: 100%;
    border-radius: unset;
    height: 48px;

    @include theme.dark {
        -apple-pay-button-style: white !important;
    }

    @include theme.light {
        -apple-pay-button-style: black !important;
    }
}
</style>
