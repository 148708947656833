/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Polyfills
import "array.prototype.at/auto";

// 3rd party
import zoid from "@/composables/useZoid";
import "@/helpers/popup-manager";

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";

// Plugins
import { registerPlugins } from "@/plugins";
const app = createApp(App);

window.fpjs = import(
    // @ts-ignore
    "https://pay.tebex.io/rid/4UnGE2zvs5uSBVYq?apiKey=yQz7Wg43xIgHnggMct6q"
).then((FingerprintJS) =>
    FingerprintJS.load({
        endpoint: [
            "https://pay.tebex.io/rid/Tv5IpwTcZBH7PqDG",
            FingerprintJS.defaultEndpoint,
        ],
    }),
);

app.provide("zoid", zoid);

registerPlugins(app);

app.mount("#app");

console.log("🚀");
